<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="px-0 mr-4 icon" outlined v-bind="attrs" v-on="on"
               :loading="loading.loadResults"
               @click="exportToTable">
          <v-icon color="#9BA1B5">mdi-upload</v-icon>
        </v-btn>
      </template>
      <span>Выгрузить в Excel</span>
    </v-tooltip>

<!--    <v-tooltip top>-->
<!--      <template v-slot:activator="{ on, attrs }">-->
<!--        <v-btn color="primary" class="px-0 mr-4 icon" outlined v-bind="attrs" v-on="on"-->
<!--               @click="dialogDeleteShow = true">-->
<!--          <v-icon color="#9BA1B5">mdi-delete</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
<!--      <span>Удалить</span>-->
<!--    </v-tooltip>-->

    <DialogDelete v-if="false" v-model="dialogDeleteShow"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import exportToTableMixin from '@/components/candidates/table/exportToTableMixin';
import DialogDelete from '@/components/candidates/DialogDelete';
import ManagersToTable from '@/common/classes/ManagersToTable';

export default {
  name: 'ManagersTableActions',
  mixins: [exportToTableMixin],
  components: {
    DialogDelete,
  },
  data: () => ({
    dialogDeleteShow: false,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/managers/loading',
    }),
  },
  methods: {
    ...mapActions({
      loadManagersResults: 'users/candidates/loadCandidatesResults',
    }),
    exportToTable() {
      this.loadManagersResults().then((resp) => {
        // eslint-disable-next-line no-new
        new ManagersToTable(resp);
      });
    },
  },
};
</script>
