<template>
  <v-data-table :headers="headers" :items="managers"
                class="px-5"
                sort-by="id" sort-desc
                :loading="loading.load"
                loading-text="Загрузка... Пожалуйста подождите"
                :mobile-breakpoint="300"
                :page="filter.page"
                :height="tableHeight"
                fixed-header
                @pagination="pagination"
                hide-default-footer
                :server-items-length="count"
                :footer-props="{itemsPerPageOptions:[count]}">

    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card class="pa-8 pa-sm-16">
            <h2>Удалить?</h2>
            <div class="mt-6">
              Вы уверены, что хотите удалить менеджера?
              <br>
              Действие не может быть отменено.
            </div>
            <div class="mt-12 d-flex justify-center">
              <v-btn @click="closeDelete" class="px-10"
                     large outlined text>Отмена</v-btn>
              <v-btn @click="deleteItemConfirm" class="ml-4 px-10"
                     color="error" large>Удалить</v-btn>
            </div>
          </v-card>
        </v-dialog>

        <ManagersTableActions v-if="selected.length" class="d-none d-sm-block" />

<!--        <ManagerCreateModalDeleteModal />-->
      </v-toolbar>
    </template>

    <!-- Header -->
    <template v-slot:header>
      <tr>
        <td class="border-bottom pa-4">
          <div>
            <v-checkbox :input-value="selected.length" @change="onChangeAllCheck"
                        :indeterminate="!!selected.length && selected.length !== count"
                        hide-details class="mt-auto" />
          </div>
        </td>
        <td class="border-bottom pa-3">
<!--          <div>-->
<!--            <v-text-field label="Название компании"-->
<!--                          append-icon="mdi-magnify"-->
<!--                          dense-->
<!--                          hide-details-->
<!--                          disabled-->
<!--                          outlined />-->
<!--          </div>-->
        </td>
        <td class="border-bottom pa-3">
<!--          <div>-->
<!--            <v-text-field label="Электронная почта"-->
<!--                          append-icon="mdi-magnify"-->
<!--                          dense-->
<!--                          hide-details-->
<!--                          disabled-->
<!--                          outlined />-->
<!--          </div>-->
        </td>
        <td class="border-bottom pa-3">
<!--          <div class="d-flex justify-lg-space-between align-center">-->
<!--            <v-text-field label="От" class="mr-1"-->
<!--                          disabled-->
<!--                          dense hide-details outlined />-->
<!--            - -->
<!--            <v-text-field label="До" class="ml-1"-->
<!--                          disabled-->
<!--                          dense hide-details outlined />-->
<!--          </div>-->
        </td>
        <td class="border-bottom pa-3"></td>
      </tr>
    </template>

    <template v-slot:item.check="{ item }">
      <div>
        <v-checkbox :input-value="selected.some((id) => id === item.id)"
                    @change="setSelected(item.id)" hide-details class="mt-auto"/>
      </div>
    </template>

    <template v-slot:item.company="{ item }">
      <div class="d-flex justify-space-between align-center">
        <div>
          {{item.company}}
        </div>
        <div class="status" :class="{active: item.company.length%4}" />
      </div>
    </template>

<!--    <template v-slot:item.actions="{ item }">-->
<!--      <v-icon @click="deleteItem(item)">mdi-delete</v-icon>-->
<!--    </template>-->

    <template v-slot:no-data>
      Корпоративных клиентов не найдено.
    </template>

    <template v-slot:footer>
      <div class="text-center py-6">
        <v-pagination v-if="paginationData"
                      :value="filter.page" @input="setFilterPage"
                      :length="paginationData.pageCount" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ManagersTableActions from '@/components/managers/table/ManagersTableActions';

export default {
  name: 'ManagersTable',
  components: {
    ManagersTableActions,
  },
  data: () => ({
    tableHeight: 400,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: '', value: 'check', sortable: false, width: '60px',
      },
      {
        text: 'Название компании', align: 'start', value: 'company', sortable: false, width: '280px',
      },
      {
        text: 'Электронная почта', value: 'email', sortable: false, width: '280px',
      },
      {
        text: 'Остаток тестов', value: '', sortable: false, width: '160px',
      },
      {
        text: '', value: 'actions', sortable: false, align: 'end',
      },
    ],
    paginationData: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/managers/loading',
      managers: 'users/managers/managers',
      errors: 'users/managers/errors',
      selected: 'users/managers/selected',
      count: 'users/managers/count',
      filter: 'users/managers/filter',
    }),
  },
  methods: {
    ...mapActions({
      loadManagers: 'users/managers/loadManagers',
      createManager: 'users/managers/createManager',
      clearError: 'users/managers/clearError',
      setSelected: 'users/managers/setSelected',
      allSelected: 'users/managers/allSelected',
      clearSelected: 'users/managers/clearSelected',
      setFilterPage: 'users/managers/setFilterPage',
    }),
    initialize() {
      this.loadManagers();
    },
    deleteItem(item) {
      this.editedIndex = this.managers.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.managers.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    pagination(data) {
      this.paginationData = data;
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.allSelected();
      } else {
        this.clearSelected();
      }
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    const height = document.documentElement.clientHeight - 270;
    this.tableHeight = Math.max(height, this.tableHeight);
  },
};
</script>
